import React from 'react';

import createIcon from './Icon';

export default createIcon(
  '0 0 20 23',
  <g>
    <path d="M7.37083 12.6026c-.70164 0-1.28549-.0571-1.72496-.1142l-1.34375 6.9595H.179688L3.83225.516163C5.08988.201006 7.07827 0 8.97801 0c1.75279 0 3.79949.286619 5.23309 1.28917 1.3172.88839 2.1354 2.23464 2.1354 4.0958 0 2.4344-1.1386 4.26703-2.6901 5.44083-1.6084 1.2333-3.8881 1.7768-6.28557 1.7768zm1.72496-9.30834c-.78902 0-1.34375.05832-1.69457.14393L6.28924 9.13832c.43821.08561.90681.14269 1.60845.14269 2.54311 0 4.20851-1.60433 4.20851-3.52257 0-1.8624-1.3741-2.46418-3.01041-2.46418zm7.22271.21844c.523.18487 1.0195.4169 1.4552.72213 1.3158.88715 2.1353 2.23339 2.1365 4.09456 0 2.43441-1.1398 4.26701-2.69 5.44201-1.6084 1.2321-3.8869 1.7756-6.2856 1.7756-.7016 0-1.28676-.0571-1.72497-.1154l-1.34501 6.9595H3.74219l.31915-1.6577h1.30702l1.34502-6.9582c.4382.057 1.02206.1141 1.72496.1141 2.39746 0 4.67586-.5447 6.28556-1.7755 1.549-1.1738 2.6888-3.00769 2.6888-5.44209 0-1.29909-.4053-2.34258-1.0942-3.15901z" />
  </g>,
);
