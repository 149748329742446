import React from 'react';

import createIcon from './Icon';

export default createIcon(
  '0 0 26 26',
  <g>
    <path
      d="M26.8534 7.33334L11.6401 22.52C11.2299 22.8643 10.7008 23.0335 10.167 22.9911C9.63316 22.9487 9.13741 22.6981 8.78675 22.2933L5.70675 19.2133C5.30202 18.8627 5.05141 18.3669 5.009 17.8331C4.9666 17.2993 5.1358 16.7702 5.48008 16.36L20.6667 1.14667C21.0783 0.80566 21.6066 0.638566 22.1394 0.680891C22.6722 0.723217 23.1675 0.971626 23.5201 1.37334L26.6001 4.45334C27.0135 4.803 27.2721 5.30181 27.3195 5.84119C27.367 6.38058 27.1994 6.91687 26.8534 7.33334ZM1.33341 27.3333H6.40008C6.53057 27.3315 6.65764 27.2914 6.76556 27.218C6.87347 27.1447 6.95748 27.0412 7.00717 26.9206C7.05686 26.7999 7.07004 26.6673 7.04507 26.5392C7.02011 26.4111 6.95811 26.2932 6.86675 26.2L1.80008 21.1333C1.7069 21.042 1.58896 20.98 1.46087 20.955C1.33278 20.93 1.20018 20.9432 1.07952 20.9929C0.958848 21.0426 0.855416 21.1266 0.782043 21.2345C0.708669 21.3424 0.668581 21.4695 0.666748 21.6V26.6667C0.666748 26.8435 0.736986 27.0131 0.86201 27.1381C0.987035 27.2631 1.1566 27.3333 1.33341 27.3333Z"
      fill="white"
    />
  </g>,
);
