import React from 'react';

import createIcon from './Icon';

export default createIcon(
  '0 0 9.33 9.33',
  <g>
    <path
      d="M8.99992 5.66665H5.66658V8.99998C5.66658 9.36665 5.36658 9.66665 4.99992 9.66665C4.63325 9.66665 4.33325 9.36665 4.33325 8.99998V5.66665H0.999919C0.633252 5.66665 0.333252 5.36665 0.333252 4.99998C0.333252 4.63331 0.633252 4.33331 0.999919 4.33331H4.33325V0.99998C4.33325 0.633313 4.63325 0.333313 4.99992 0.333313C5.36658 0.333313 5.66658 0.633313 5.66658 0.99998V4.33331H8.99992C9.36658 4.33331 9.66658 4.63331 9.66658 4.99998C9.66658 5.36665 9.36658 5.66665 8.99992 5.66665Z"
      fill="#ffffff"
    />
  </g>,
);
